import type { ProductsQueryVariables } from '@robustastudio/e-commerce/common';

export const useCompareOverlayStore = () => {
  const store = useState<{
    isOpen: boolean;
  }>('overlay', () => ({
    isOpen: true,
  }));

  const setIsOpen = (isOpen: boolean) => {
    store.value.isOpen = isOpen;
  };

  const isOpen = computed(() => store.value.isOpen);
  return {
    isOpen,
    setIsOpen,
  };
};

export const useCompareProducts = () => {
  const { skus } = useComparisonState();

  const variables = computed<ProductsQueryVariables>(() => {
    return {
      filter: {
        sku: {
          in: skus?.value?.length ? skus?.value?.map((sku: any) => sku.sku) : ['xyz-xyz'],
        },
      },
    };
  });

  const { products, isFetching } = useGenericExtendedFetchProduct('default', variables);

  const itemsWithAddNodes = computed(() => {
    return products.value.length < 4 ? [...products.value, null] : products.value;
  });
  const category = computed(() => {
    return products.value[0]?.categories?.[0] || { name: '', url: '' };
  });

  return {
    products,
    itemsWithAddNodes,
    category,
    isFetching,
    skus,
  };
};

export const useCompareItem = () => {
  const { error } = useAlerts();
  const { skus } = useComparisonState();
  const { t } = useI18n();
  const appCompareItem = async ({ sku, categories }: { sku: string; categories: number[] }) => {
    try {
      if (skus.value.length >= 4) {
        throw new Error('compareError:limitExceeded');
      }
      await compareItem({
        sku,
        categoryIds: categories,
      });
    } catch (e) {
      if (/invalidComparison/.test((e as Error).message)) {
        error(t('comparisonError'), t('invalidComparisonError'));
        return;
      }
      if (/compareError:limitExceeded/.test((e as Error).message)) {
        error(t('comparisonError'), t('compareError:limitExceeded'));
        return;
      }
      error((e as Error).message);
    }
  };

  return {
    appCompareItem,
  };
};
